<template>
  <div class="export-btn">
    <el-button
      size="small"
      type="primary"
      @click="exportExcel"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      >导出表格</el-button
    >
  </div>
</template>

<script>
import { export_json_to_excel } from '@/commons/Export2Excel.js'
import { deepClone } from '@/components/avue/utils/util'
export default {
  components: {},
  props: {
    styleSelectData: {
      type: Array,
      default: []
    },
    query: {
      type: Object,
      default: {}
    },
    getSelectionDataAllArr: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      allData: [],
      loading: false
    }
  },
  computed: {},
  methods: {
    // 点击按钮
    async exportExcel() {
      let selectionDataAllArr = await this.getSelectionDataAllArr()
      if (!selectionDataAllArr.length) return
      console.log('selectionDataAllArr', selectionDataAllArr)
      this.allData = selectionDataAllArr
      // this.remind()
      this.isExportExcel()
    },
    // 提醒
    /*  remind() {
      this.$confirm('是否确认导出已选数据？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '导出成功'
          })
          // 确定后导出表格
          this.isExportExcel()
        })
        .catch((action) => {
          this.$message({
            type: 'info',
            message: action === 'cancel' ? '取消导出' : '取消导出'
          })
        })
    }, */
    // 格式化数据
    excelDataHandle(exportData) {
      const data = []
      exportData.map(({ categoryName, styleName, sizeList }) => {
        sizeList.map(({ sizeName, productCount }) => {
          const dataItems = [categoryName, styleName, sizeName, productCount]
          data.push(dataItems)
        })
      })
      return data
    },
    // 格式化导出文件的文件名
    getExcelName(start = null, end = null) {
      if (start && end) {
        return `${start}-${end}款式数据统计`
      } else {
        return '款式数据统计'
      }
    },
    // 导出excel
    isExportExcel() {
      const exportData = deepClone(this.allData)
      console.log('exportData', exportData)
      // return
      // 调用格式化数据的方法
      const data = this.excelDataHandle(exportData)
      // console.log('data', data)
      const { startCreateTime, endCreateTime } = this.query
      const excelName = this.getExcelName(startCreateTime, endCreateTime)
      const tHeader = ['产品分类', '款式编码', '尺寸', '销售数量']
      export_json_to_excel({
        header: tHeader, //表头 必填
        data, //具体数据 必填
        filename: excelName, //非必填
        autoWidth: true, //非必填
        bookType: 'xlsx' //非必填
      })
    }
  }
}
</script>

<style scoped lang="scss">
.export-btn {
  display: inline-block;
  margin-left: 15px;
}
::v-deep {
  .el-loading-spinner {
    top: 100%;
  }
}
</style>
